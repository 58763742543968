import {ModuleWithProviders} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {registerLocaleData} from "@angular/common";
import {TranslateCompiler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateMessageFormatCompiler} from "ngx-translate-messageformat-compiler";

import {environment} from 'src/environments/environment';

import localeBe from '@angular/common/locales/fr-BE';
import localeNl from '@angular/common/locales/nl-BE';

import localeBeExtra from '@angular/common/locales/extra/fr-BE';
import localeNlExtra from '@angular/common/locales/extra/nl-BE';

registerLocaleData(localeBe, 'fr', localeBeExtra);
registerLocaleData(localeNl, 'nl', localeNlExtra);

// NOTE: As of 30/07/2024, ngx-translate does not have standalone support

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?noCache=' + new Date().getTime());
}

export const i18nProviders: ModuleWithProviders<any>[] = [
    TranslateModule.forRoot({
        compiler: {
            provide: TranslateCompiler,
            useClass: TranslateMessageFormatCompiler,

        },
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
];
