import {AuthConfig} from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

    // Url of the Identity Provider (https://oidc.konekto.be/auth/realms/spurado-icity/.well-known/openid-configuration)
    issuer: 'https://login.microsoftonline.com/bb2cf736-1de4-46d6-9004-4bde21a93163/v2.0',

    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin + '/index.html',

    postLogoutRedirectUri: window.location.origin,

    // URL of the SPA to redirect the user after silent refresh
    silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',

    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'a7388323-51bf-4fe7-b7ae-3d084786b751',

    responseType: 'code',

    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'openid profile email api://spuradotest/backend',

    strictDiscoveryDocumentValidation: false,

    showDebugInformation: false,

    timeoutFactor: 0.8,
};
