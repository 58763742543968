import {Account, Activity, Approval, Program, Project, UserGroup} from './spurado';
import {ChartType} from 'chart.js';

export enum Criticity {
    INFO = 'notification-info',
    WARNING = 'notification-warning',
    ERROR = 'notification-error'
}

export class UserData {
    account: Account;
    uuid: string;
    admin: boolean;
    displayName: string;
    email: string;
    groups: number;
}

export class GroupData {
    userGroup: UserGroup;
    name: string;
    members: number;
    systemGroup: boolean;
}

export class ProjectData extends Project {
    nbrOfParticipants: number;
    clientName: string;
    programName: string;
    approbatorName: string;

    constructor(project: Project) {
        super();
        this.name = project.name;
        this.code = project.code ? project.code : '-';
        this.mainApprobator = project.mainApprobator;
        this.participants = project.participants;
        this.client = project.client;
        this.active = project.active;
        this.uuid = project.uuid;
        this.version = project.version;
        this.editable = project.editable;
        this.removable = project.removable;
        this.program = project.program;
        this.externallyManaged = project.externallyManaged;
        this.accessibleForInternalUsers = project.accessibleForInternalUsers;
        this.accessibleForExternalUsers = project.accessibleForExternalUsers;

        this.nbrOfParticipants = project.participants ? project.participants.length : 0;
        this.clientName = this.client ? this.client.name : '-';
        this.programName = this.program ? this.program.name : '-';
        this.approbatorName = (this.mainApprobator && this.mainApprobator.user) ? this.mainApprobator.user.displayName : '-';
    }
}

export class ActivityData extends Activity {
    clientName: string;
    approbatorName: string;

    constructor(activity: Activity) {
        super();
        this.uuid = activity.uuid;
        this.code = activity.code ? activity.code : '-';
        this.version = activity.version;
        this.name = activity.name;
        this.mainApprobator = activity.mainApprobator;
        this.client = activity.client;
        this.active = activity.active;
        this.favorite = activity.favorite;
        this.accessibleForInternalUsers = activity.accessibleForInternalUsers;
        this.accessibleForExternalUsers = activity.accessibleForExternalUsers;

        this.clientName = this.client ? this.client.name : '-';
        this.approbatorName = (this.mainApprobator && this.mainApprobator.user) ? this.mainApprobator.user.displayName : '-';
    }
}

export class ProgramData extends Program {
    sponsorName: string;

    constructor(program: Program) {
        super();

        this.uuid = program.uuid;
        this.name = program.name;
        this.sponsor = program.sponsor;

        this.sponsorName = (this.sponsor && this.sponsor.user) ? this.sponsor.user.displayName : '-';
    }
}

export class ApprovalData extends Approval {
    timesheetIdentification: string;
    awaitingPerson: string;
    approbatorPerson: string;

    constructor(approval: Approval) {
        super();

        this.timesheetIdentification = approval.timesheetYear + '/' + approval.timesheetWeekNumber + ' (' + approval.timesheetMonth + ')';
        this.awaitingPerson = approval.account.user.displayName;
        this.approbatorPerson = approval.approbator.user.displayName;
        this.taskName = approval.taskName;
        this.totalDuration = approval.totalDuration;
        this.creationDate = approval.creationDate;
    }
}

export interface CheckboxElement {
    uuid: string;
    value: string;
    completed: boolean;
    subElements?: CheckboxElement[];
}

export const DATE_DAY_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export const DATE_MONTH_FORMAT = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export class ChartData {
    constructor(labelKey: string, value: number) {
        this.labelKey = labelKey;
        this.value = value;
    }

    labelKey: string;
    value: number;
}

export class StatChart {
    data: ChartData[];
    typeGraph: ChartType;
    titleKey?: string;
    generatedAt?: Date;
}

export declare type ThemePaletteTile =
    'primary-square'
    | 'accent-square'
    | 'warn-square'
    | 'primary-w-240'
    | 'accent-w-240'
    | 'warn-w-240'
    | 'primary-w-auto'
    | 'accent-w-auto'
    | 'warn-w-auto'
    | undefined;

//TODO de Vincent à Cedrick : fais une recherche sur 'export enum' dans ton IntelliJ et regarde la tête des éléments dans une enum :-)
export enum FilterType {
    DATE = 'approval_filter.type.date',
    ACCOUNT = 'approval_filter.type.account',
    CUSTOMER = 'approval_filter.type.customer',
    TASK = 'approval_filter.type.task',
    PROGRAM = 'approval_filter.type.program'
}

export enum ApprovalLayout {
    CARD,
    LIST
}

export class FilterElement {
    type: FilterType;
    name: string;
    uuid: string;
    from: Date;
    to: Date;
}

export class SaveFilters {
    orgUuid: string;
    accountUuid: string;
    readonly expiryAt: number;
    filterElements = new Array<FilterElement>();

    constructor(minute: number) {
        const date = new Date();
        date.setMinutes(date.getMinutes() + minute);
        this.expiryAt = date.getTime();
    }

}
