/* tslint:disable */
/* eslint-disable */

export class AccountAttribute {
    key?: SpecificAccountAttribute;
    value?: string;
}

export class AccountCreation {
    email?: string;
}

export class AccountDelegation {
    uuid?: string;
    delegateeUuid?: string;
    delegateeDisplayName?: string;
    delegateeEmail?: string;
    delegatorUuid?: string;
    delegatorDisplayName?: string;
    delegatorEmail?: string;
    validFrom?: Date;
    validTo?: Date;
    pendingApprovals?: number;
}

export class EditableObject {
    editable?: boolean;
    removable?: boolean;
}

export class Account extends EditableObject {
    uuid?: string;
    organisation?: Organisation;
    user?: User;
    groups?: UserGroup[];
    attributes?: AccountAttribute[];
    isAdmin?: boolean;
    isReporter?: boolean;
    impersonated?: boolean;
}

export class AccountRef {
    uuid?: string;
    user?: User;
}

export class Task {
    uuid?: string;
    code?: string;
    version?: number;
    name?: string;
    client?: ClientRef;
    favorite?: boolean;
    externallyManaged?: boolean;
    accessibleForInternalUsers?: boolean;
    accessibleForExternalUsers?: boolean;
}

export class Activity extends Task {
    mainApprobator?: AccountRef;
    active?: boolean;
    editable?: boolean;
    removable?: boolean;
}

export class AddLine {
    taskUuid?: string;
    clientUuid?: string;
}

export class ApplicationDetails {
    version?: string;
    environment?: string;
    uniqueOrganisationUuid?: string;
    mainUrl?: string;
}

export class Approval {
    timesheetYear?: number;
    timesheetMonth?: number;
    timesheetWeekNumber?: number;
    timesheetWeekAndMonth?: string;
    timesheetUuid?: string;
    timesheetLineSequence?: string;
    timesheetLineVersion?: number;
    account?: AccountRef;
    approbator?: AccountRef;
    totalDuration?: number;
    userDisplayName?: string;
    taskName?: string;
    taskUuid?: string;
    programName?: string;
    programUuid?: string;
    clientName?: string;
    clientUuid?: string;
    creationDate?: Date;
}

export class ApprovalStatusUpdate {
    newStatus?: LineStatus;
    refuseComment?: string;
    approvals?: Approval[];
}

export class OfficialInfo extends EditableObject {
    vatCountryCode?: string;
    vatNumber?: string;
    officialName?: string;
    officialStreetNumber?: string;
    officialPostCity?: string;
    officialCountry?: string;
    mainUrl?: string;
    contactEmail?: string;
    officialRpmCity?: string;
}

export class Client extends OfficialInfo {
    uuid?: string;
    name?: string;
}

export class ClientRef {
    uuid?: string;
    name?: string;
}

export class Configuration {
    uuid?: string;
    enableApprob?: ConfigurationElement<boolean>;
    enableWorkDuringWeekend?: ConfigurationElement<boolean>;
    enableWorkDuringLegalHoliday?: ConfigurationElement<boolean>;
    enableEditPersonalHoliday?: ConfigurationElement<boolean>;
    workingHoursPerDay?: ConfigurationElement<number>;
    availableThemes?: ConfigurationElement<Theme[]>;
    selectedTheme?: ConfigurationElement<Theme>;
    enableReports?: ConfigurationElement<boolean>;
    enableSubmitChecks?: ConfigurationElement<boolean>;
    enableAdminTimesheets?: ConfigurationElement<boolean>;
    enableAdminStatistics?: ConfigurationElement<boolean>;
    enableDelegation?: ConfigurationElement<boolean>;
    dailyPointing?: ConfigurationElement<boolean>;
    enablePdfTimesheet?: ConfigurationElement<boolean>;
    pdfTimesheetWaitDays?: ConfigurationElement<number>;
    pdfTimesheetSplitClient?: ConfigurationElement<boolean>;
    pdfTimesheetMaxPrjLine?: ConfigurationElement<number>;
    version?: number;
}

export class ConfigurationElement<T> {
    value?: T;
    readOnly?: boolean;
}

export class RawReportingLine {
    category?: ReportedTaskType;
    date?: Date;
    weeklyYear?: number;
    realYear?: number;
    month?: number;
    week?: number;
    /**
     * @deprecated
     */
    name?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    taskName?: string;
    duration?: number;
    status?: LineStatus;
    comment?: string;
    client?: string;
    program?: string;
    nextApprobatorEmail?: string;
}

export class ICityReportingLine extends RawReportingLine {
    cellule?: string;
    department?: string;
    analyticCode?: string;
    workerType?: string;
}

export class InfoMessage {
    contentFr?: string;
    contentNl?: string;
}

export class License {
    uuid?: string;
    startDate?: Date;
    endDate?: Date;
    maxUsers?: number;
    maxTasks?: number;
    enableMultiOrganisation?: boolean;
}

export class LineStatusUpdate {
    version?: number;
    status?: LineStatus;
    comment?: string;
}

export class OidcAccount {
    uuid?: string;
    issuer?: string;
    subject?: string;
    email?: string;
}

export class Organisation extends OfficialInfo {
    uuid?: string;
    name?: string;
    logoUrl?: string;
    license?: License;
    configuration?: Configuration;
}

export class PersonalHoliday {
    uuid?: string;
    date?: Date;
    duration?: HolidayDuration;
}

export class Program extends EditableObject {
    uuid?: string;
    name?: string;
    sponsor?: AccountRef;
}

export class ProgramRef {
    uuid?: string;
    name?: string;
}

export class Project extends Task {
    participants?: UserGroup[];
    mainApprobator?: AccountRef;
    program?: ProgramRef;
    active?: boolean;
    editable?: boolean;
    removable?: boolean;
}

export class SearchApprovals {
    dateFrom?: Date;
    dateTo?: Date;
    accountUuids?: string[];
    taskUuids?: string[];
    clientUuids?: string[];
    programUuids?: string[];
}

export class Timesheet2PDFLine {
    date?: Date;
    lineContents?: string[];
    totalDuration?: number;
    pendingDuration?: number;
    holiday?: HolidayDuration;
}

export class TimesheetDay {
    number?: number;
    actualDuration?: number;
}

export class Week {
    year?: number;
    month?: number;
    weekNumber?: number;
}

export class Timesheet extends Week {
    uuid?: string;
    week?: { [index: string]: WeekDay };
    timesheetLines?: TimesheetLine[];
    previousWeek?: Week;
    nextWeek?: Week;
    account?: AccountRef;
    status?: TimesheetStatus;
    enableRecall?: boolean;
    enableAddMoreTask?: boolean;
    enableCopyTasksFromPreviousWeek?: boolean;
}

export class TimesheetLine {
    sequence?: string;
    version?: number;
    task?: Task;
    comment?: string;
    client?: ClientRef;
    status?: LineStatus;
    lastApprobator?: User;
    timesheetDays?: TimesheetDay[];
    allowedActions?: TimesheetLineAuthorization[];
}

export class TimesheetLineVersionRef {
    sequence?: string;
    version?: number;
}

export class User {
    uuid?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    locale?: SupportedLocale;
    displayName?: string;
}

export class UserGroup extends EditableObject {
    uuid?: string;
    name?: string;
    systemGroup?: boolean;
    members?: AccountRef[];
}

export class UserLocaleUpdate {
    locale?: SupportedLocale;
}

export class VersionedTimesheetClient {
    version?: number;
    clientUuid?: string;
}

export class VersionedTimesheetComment {
    version?: number;
    comment?: string;
}

export class VersionedTimesheetDays {
    version?: number;
    days?: TimesheetDay[];
}

export class ViesResponse {
    countryCode?: string;
    vatNumber?: string;
    name?: string;
    streetAndNumber?: string;
    postalCodeAndCity?: string;
    country?: string;
}

export class WeekDay {
    number?: number;
    date?: Date;
    personalHoliday?: PersonalHoliday;
    editable?: boolean;
    legalHoliday?: boolean;
    weekDay?: boolean;
}

export class WeekSummary {
    client?: string;
    weekSummary?: number;
    pourcentageOfWeekTotal?: number;
}

export class ICityDepartment {
    name?: string;
    divisions?: string[];
}

export class ActualsStats {
    timesheetsStat?: TimesheetsStat;
    hoursStat?: HoursStat;
    generatedAt?: Date;
}

export class ApprovalStat {
    approved?: number;
    pending?: number;
    rejected?: number;
}

export class SentStat {
    sentAndValidated?: number;
    sentAndWaiting?: number;
    notSent?: number;
}

export class HoursStat extends SentStat {
    encoded?: number;
}

export class TasksStat {
    tasksAtBegin?: number;
    tasksAtEnd?: number;
    tasksCreated?: number;
    tasksDeleted?: number;
    tasksUsed?: number;
    generatedAt?: Date;
}

export class TimesheetsStat extends SentStat {
    created?: number;
}

export class ValidationIssue {
    blocking?: boolean;
}

export class EmptyCommentIssue extends ValidationIssue {
    taskName?: string;
}

export class HoursIssue extends ValidationIssue {
    expected?: number;
    actual?: number;
}

export class HoursInDayIssue extends HoursIssue {
    date?: Date;
}

export class HoursInWeekIssue extends HoursIssue {
}

export class TimesheetValidation {
    emptyCommentIssues?: EmptyCommentIssue[];
    hoursInDayIssues?: HoursInDayIssue[];
    hoursInWeekIssues?: HoursInWeekIssue;
}

export enum AccountType {
    INTERNAL = "INTERNAL",
    EXTERNAL = "EXTERNAL",
}

export enum Action {
    ADD = "ADD",
    EDIT = "EDIT",
    DELETE = "DELETE",
    COPY = "COPY",
    APPROVE = "APPROVE",
    REJECT = "REJECT",
}

export enum ApprovalStatus {
    PENDING = "PENDING",
    APROVED = "APROVED",
    REJECTED = "REJECTED",
    RECALLED = "RECALLED",
    DELETED = "DELETED",
}

export enum DayType {
    WEEK = "WEEK",
    WEEK_END = "WEEK_END",
}

export enum ErrorCode {
    GENERIC_FAILURE = "GENERIC_FAILURE",
    NOT_FOUND = "NOT_FOUND",
    MAX_USER_LIMIT_REACHED = "MAX_USER_LIMIT_REACHED",
    MAX_TASKS_LIMIT_REACHED = "MAX_TASKS_LIMIT_REACHED",
    USER_IS_ADMIN = "USER_IS_ADMIN",
    USER_ALREADY_IN_OTHER_ORGANISATION = "USER_ALREADY_IN_OTHER_ORGANISATION",
    INVALID_EMAIL_ADDRESS_FORMAT = "INVALID_EMAIL_ADDRESS_FORMAT",
    DATA_UPDATED_IN_OTHER_SESSION = "DATA_UPDATED_IN_OTHER_SESSION",
    USER_GROUP_NAME_ALREADY_EXISTS = "USER_GROUP_NAME_ALREADY_EXISTS",
    INVALID_VALUE = "INVALID_VALUE",
    DATA_ALREADY_EXIST = "DATA_ALREADY_EXIST",
}

export enum HolidayDuration {
    AM = "AM",
    PM = "PM",
    FD = "FD",
}

export enum LineStatus {
    OPEN = "OPEN",
    PENDING_APPROVAL = "PENDING_APPROVAL",
    VALIDATED = "VALIDATED",
    DELETED = "DELETED",
}

export enum SpecificAccountAttribute {
    DEPARTMENT = "DEPARTMENT",
    CELL = "CELL",
    MANAGER = "MANAGER",
    EMPLOYEE_TYPE = "EMPLOYEE_TYPE",
}

export enum SupportedLocale {
    FR = "FR",
    NL = "NL",
}

export enum SystemGroup {
    SUPER_ADMIN = "SUPER_ADMIN",
    SUPER_IMPERSONATOR = "SUPER_IMPERSONATOR",
    INTEGRATORS = "INTEGRATORS",
    SUPER_REPORTER = "SUPER_REPORTER",
}

export enum Theme {
    NEUTRAL = "NEUTRAL",
    SPURADO = "SPURADO",
    WIB = "WIB",
    ICITY = "ICITY",
}

export enum TimesheetLineAuthorization {
    EDIT_COMMENT = "EDIT_COMMENT",
    VIEW_COMMENT = "VIEW_COMMENT",
    DUPLICATE = "DUPLICATE",
    DELETE = "DELETE",
    APPROVE = "APPROVE",
    REJECT = "REJECT",
    EDIT_CLIENT = "EDIT_CLIENT",
}

export enum TimesheetMode {
    USER = "USER",
    ADMIN = "ADMIN",
    APPROVAL = "APPROVAL",
}

export enum TimesheetStatus {
    UNKNOWN = "UNKNOWN",
    EDITABLE = "EDITABLE",
    PRE_FINAL = "PRE_FINAL",
    FINAL = "FINAL",
}

export enum WarningCode {
    DELETED_ACCOUNT_WAS_APPROBATOR = "DELETED_ACCOUNT_WAS_APPROBATOR",
}

export enum ReportedTaskType {
    ACTIVITY = "ACTIVITY",
    PROJECT = "PROJECT",
    ABSENCE = "ABSENCE",
}
